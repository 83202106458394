import classNames from 'classnames'
import { contrastBW } from 'lib/color'
import { motion } from 'lib/framer-motion'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Slash } from 'react-feather'
import { useIntl } from 'react-intl'

import Spinner from '../Spinner'

const DisabledProps = (props) => {
  const { disabled, messageId, messageProps, linkPath, linkText } = props
  const { locale, formatMessage } = useIntl()

  if (!messageId && !linkPath) return null

  const learnMore = formatMessage({ id: 'button.learnMore' })
  const message = messageId && formatMessage({ id: messageId }, messageProps)
  const msgPunctuation = message?.charAt(message.length - 1)
  const spacing = ['.', '?', '!'].includes(msgPunctuation) ? '' : '. '

  return (
    <div className="flex p-1 opacity-60 cursor-default">
      {disabled ? (
        <Slash size={12} fill="red" color="white" className="flex-0 m-1" />
      ) : (
        <span className="text-sm">&nbsp;</span>
      )}
      <div className="flex-1 items-center inline space-x-2 text-sm">
        {message && message + spacing}
        {linkPath && (
          <span className="underline">
            <Link href={`/${locale}/${linkPath}`}>
              {formatMessage({ id: linkText, defaultMessage: learnMore })}
            </Link>
          </span>
        )}
      </div>
    </div>
  )
}

DisabledProps.propTypes = {
  disabled: PropTypes.boolean,
  messageId: PropTypes.string,
  messageProps: PropTypes.object,
  reason: PropTypes.string,
  linkPath: PropTypes.string,
  linkText: PropTypes.string,
}

// main difference: Button is a <button> with a spinner vs. ButtonLink is an <a> without spinner
// @TODO: ButtonLink also requires a Link wrapper, which should just be included here
// @TODO: lots of repetition here...could we export a single component as use an "as" prop
//        or something like that to determine the sort of element that will render? (i.e. a, div, button...)

const buttonClasses = ({
  color = 'black',
  weight = 'font-bold',
  className,
  full,
  wide,
  variant = 'primary',
  disabled,
  square,
}) => {
  const textColor = contrastBW(color)

  return classNames(
    `inline-flex justify-center py-2 border-2 relative select-none whitespace-nowrap`,
    {
      'border-0 p-0 px-2': variant === 'icon',
      [`bg-${color} text-${textColor} ${weight} border-transparent`]:
        variant === 'primary',
      [`border-solid border-${color} ${weight} text-${color}`]:
        variant === 'secondary',
      [`border-solid ${weight}`]: variant === 'session',
      [`border-solid border-backgroundColor ${weight} text-backgroundTextColor text-primaryContrasts bg-backgroundColor`]:
        variant === 'selected',
      'w-full': full,
      'opacity-20 pointer-events-none': disabled, // pointer-events-none needed to prevent react-laag bug in Tooltip
      'cursor-pointer': !disabled,
      'rounded-md': square,
      'rounded-full': !square,
      'px-12': wide,
      'px-8': !wide && variant !== 'icon',
    },
    className
  )
}

export const ButtonDiv = forwardRef(
  (
    { target, variant, full, wide, square, children, className, ...aProps },
    ref
  ) => (
    <motion.div
      className={buttonClasses({ variant, full, wide, square, className })}
      ref={ref}
      target={target || '_self'}
      whileTap={{ y: 2 }}
      {...aProps}
    >
      {children}
    </motion.div>
  )
)

ButtonDiv.displayName = 'ButtonDiv'

export const ButtonLink = forwardRef(
  (
    {
      target,
      variant,
      full,
      wide,
      children,
      square,
      className,
      disabled,
      ...aProps
    },
    ref
  ) => (
    <motion.a
      className={buttonClasses({
        variant,
        full,
        wide,
        square,
        className,
        disabled,
      })}
      ref={ref}
      target={target || '_self'}
      whileTap={{ y: 2 }}
      {...aProps}
    >
      {children}
    </motion.a>
  )
)

ButtonLink.displayName = 'ButtonLink'

const Button = forwardRef(
  (
    {
      color,
      variant,
      textColor,
      weight,
      full,
      wide,
      children,
      className,
      loading,
      noTapMotion,
      target,
      square,
      disabled,
      disabledProps,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onRemoveClick,
      type,
      ...buttonProps
    },
    ref
  ) => (
    <div
      className={classNames(
        'flex items-center',
        full ? 'flex-col' : 'flex-wrap',
        disabled && 'cursor-not-allowed'
      )}
    >
      <motion.button
        ref={ref}
        whileTap={!noTapMotion && !disabled && { y: 2 }}
        target={target || '_self'}
        disabled={disabled || loading}
        className={buttonClasses({
          color,
          variant,
          full,
          wide,
          weight,
          textColor,
          disabled,
          square,
          className,
        })}
        type={type || 'button'}
        {...buttonProps}
      >
        {loading && (
          <div
            className={`absolute inset-0 flex justify-center rounded-full items-center bg-${color}`}
          >
            <Spinner />
          </div>
        )}
        {children}
      </motion.button>
      {disabledProps && <DisabledProps {...disabledProps} />}
    </div>
  )
)

Button.displayName = 'Button'

Button.defaultProps = {
  color: 'black',
  variant: 'primary',
}

Button.propTypes = {
  color: PropTypes.string,
  square: PropTypes.bool,
  full: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node,
  groupStyle: PropTypes.object,
}

export default Button
